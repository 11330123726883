<template>
  <XadminLayout> xadmin-tarrifs </XadminLayout>
</template>

<script>
import XadminLayout from "@/modules/xadmin/layout";

export default {
  name: "xadmin-tarrifs",

  components: {
    XadminLayout,
  },
};
</script>

<style>
</style>