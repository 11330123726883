<template>
  <div class="flex flex-col items-start justify-start pl-2">
    <div><i class="fa-solid fa-folder"></i> Ccntacts</div>
    <div><i class="fa-solid fa-folder"></i> Phones</div>
    <div><i class="fa-solid fa-folder"></i> Reports</div>
    <div><i class="fa-solid fa-folder"></i> Roles</div>
    <div><i class="fa-solid fa-folder"></i> Tasks</div>
    <div><i class="fa-solid fa-folder"></i> Help</div>
  </div>
</template>

<script>
export default {
  name: "SidebarMaxIcons",
  props: {
   headersData: {
      type: Array,
      required: true,
    },
    openRightSidebar: {
      type: Boolean,
      default: false,
    },
    leftSidebarMinPosition: {
      type: Number,
      default: 2,
    },
    leftSidebarMaxPosition: {
      type: Number,
      default: 10,
    },
    rightSidebarMinPosition: {
      type: Number,
      default: 2,
    },
    rightSidebarMaxPosition: {
      type: Number,
      default: 10,
    },
  },
  setup() {
    const openLeft = () => {
      this.$emit("openLeft");
    };

    const openRight = () => {
      this.$emit("openRight");
    };

    return {
      openLeft,
      openRight,
    };
  },
};
</script>

<style>
</style>