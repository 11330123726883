<template>
  <div class="">
    <div class="flex gap-2 border-b-2 border-gray-600 tab-container">
      <p
        class="cursor-pointer px-1 tab-item"
        @click="setActiveTab(1)"
        :class="{ active: activeTab === 1 }"
      >
        Menu 1
      </p>
      <p
        class="cursor-pointer px-1 tab-item"
        @click="setActiveTab(2)"
        :class="{ active: activeTab === 2 }"
      >
        Menu 2
      </p>
      <p
        class="cursor-pointer px-1 tab-item"
        @click="setActiveTab(3)"
        :class="{ active: activeTab === 3 }"
      >
        Menu 3
      </p>
    </div>

    <div class="p-2">
      <Item1 v-if="activeTab === 1" />
      <Item2 v-if="activeTab === 2" />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import Item1 from "@/modules/xadmin/components/right-sidebar-menu/components/right-sidebar-menu-item1";
import Item2 from "@/modules/xadmin/components/right-sidebar-menu/components/right-sidebar-menu-item2";

export default {
  name: "RightSidebarMenu",

  components: {
    Item1,
    Item2,
  },

  setup() {
    const activeTab = ref(1);

    const setActiveTab = (tab) => {
      activeTab.value = tab;
    };

    return {
      activeTab,
      setActiveTab,
    };
  },
};
</script>

<style lang="scss" scoped>
.tab-item.active {
  background-color: #f3f4f6;
  color: #000;
}
</style>