export const headerData = {
  contacts: [
    {
      title: "Dashboard",
      icon: "mdi-view-dashboard",
      to: "/xadmin/dashboard",
      link: "xadmin-dashboard",
    },
    {
      title: "Tariffs",
      icon: "mdi-currency-usd",
      to: "/xadmin/tariffs",
      link: "xadmin-tarrifs",
    },
    {
      title: "Users",
      icon: "mdi-account",
      to: "/xadmin/users",
      link: "xadmin-dashboard",
    },
  ],

  phones: [
    {
      title: "Phones",
      icon: "mdi-phone",
      to: "/xadmin/phones",
      link: "xadmin-dashboard",
    },
    {
      title: "Contacts",
      icon: "mdi-account",
      to: "/xadmin/contacts",
      link: "xadmin-dashboard",
    },
  ],

  settings: [
    {
      title: "Appearance",
      icon: "mdi-palette",
      to: "/xadmin/appearance",
      link: "xadmin-dashboard",
    },
    {
      title: "Sidebar",
      icon: "mdi-view-compact",
      to: "/xadmin/sidebar",
      link: "xadmin-dashboard",
    },
    {
      title: "Languages",
      icon: "mdi-translate",
      to: "/xadmin/languages",
      link: "xadmin-dashboard",
    },
  ],
};
