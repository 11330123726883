<template>
  <div class="right-sidebar-menu-item1">right-sidebar-menu-item 2</div>
</template>

<script>
export default {
  name: "right-sidebar-menu-item2",
};
</script>

<style>
</style>