<template>
  <section>
    <!-- <headerSection /> -->
    <header class="header flex gap-4 items-center py-1">
      <img src="@/assets/images/config-x.svg" alt="xadmin-logo" class="w-14" />
      <div v-if="getHeaders?.length" class="flex gap-2">
        <div v-for="header in getHeaders" :key="header.id">
          <p class="border border-gray-300 cursor-pointer px-2 rounded-md">
            <router-link :to="{ name: header.link }" v-if="header?.link">
              {{ header.title }}
            </router-link>
          </p>
        </div>
      </div>
    </header>
    <div class="main-layout" @mouseup="stopDragging">
      <!-- Left Sidebar -->
      <div
        class="left-sidebar"
        :style="{
          width: `${leftSidebarPosition}%`,
        }"
      >
        <i
          class="fa-solid fa-circle-chevron-right cursor-pointer text-green-300"
          @click="openLeft"
        ></i>

        <SidebarMinIcons v-if="!openLeftSidebar" :headersData="headersData" />
        <SidebarMaxIcons v-else :headersData="headersData" />
      </div>
      <div
        class="left-sidebar-divider"
        :style="{
          left: `${leftSidebarPosition}%`,
        }"
        @mousedown="startDragging"
      ></div>
      <!-- Main Content -->
      <section class="main-content">
        <slot></slot>
      </section>
      <div
        class="right-sidebar"
        :style="{
          width: `${rightSidebarPosition}%`,
        }"
      >
        <i
          class="fa-solid fa-circle-chevron-left cursor-pointer text-green-300"
          @click="openRight"
        ></i>
        <RightSidebarMenu v-if="openRightSidebar" />
      </div>
      <div class="rigth-sidebar-divider"></div>
    </div>
    <div class="bottom-sidebar">Footer</div>
  </section>
</template>

<script>
import { useStore } from "vuex";
import { ref, computed, onMounted } from "vue";
import SidebarMinIcons from "@/modules/xadmin/components/sidebar-min-icons";
import SidebarMaxIcons from "@/modules/xadmin/components/sidebar-max-icons";
import RightSidebarMenu from "@/modules/xadmin/components/right-sidebar-menu";
import { headerData } from "@/modules/xadmin/mockup/header";
// import headerSection from "@/modules/common/components/header-section/index";

export default {
  name: "xadmin-layout",

  components: {
    SidebarMinIcons,
    SidebarMaxIcons,
    RightSidebarMenu,
    // headerSection
  },

  setup() {
    const checked = ref(false);
    const store = useStore();
    const headersData = ref([]);

    const openLeftSidebar = ref(false);
    const openRightSidebar = ref(false);
    const leftSidebarMinPosition = ref(2);
    const leftSidebarMaxPosition = ref(10);

    const rightSidebarMinPosition = ref(2);
    const rightSidebarMaxPosition = ref(14);

    const leftSidebarPosition = computed(() => {
      return openLeftSidebar.value
        ? leftSidebarMaxPosition.value
        : leftSidebarMinPosition.value;
    });

    const rightSidebarPosition = computed(() => {
      return openRightSidebar.value
        ? rightSidebarMaxPosition.value
        : rightSidebarMinPosition.value;
    });

    const openLeft = () => {
      openLeftSidebar.value = !openLeftSidebar.value;
    };

    const openRight = () => {
      openRightSidebar.value = !openRightSidebar.value;
    };

    const handleDragging = (e) => {
      const percentage = (e.pageX / window.innerWidth) * 100;

      if (percentage >= 10 && percentage <= 20) {
        leftSidebarMaxPosition.value = percentage.toFixed(2);
      }
    };

    const startDragging = () => {
      window.addEventListener("mousemove", handleDragging);
    };

    const stopDragging = () => {
      window.removeEventListener("mousemove", handleDragging);
    };

    const getHeaders = computed(() => store.state.xadmin.header);

    const setHeader = async (name) => {
      const header = headerData[name];
      headersData.value = headerData;

      if (header) {
        store.commit("xadmin/SET_XADMIN_HEADER", header);
      }
    };

    onMounted(() => {
      setHeader("contacts");
    });

    return {
      checked,
      getHeaders,
      headersData,
      openLeftSidebar,
      openRightSidebar,
      leftSidebarMinPosition,
      rightSidebarMinPosition,
      leftSidebarMaxPosition,
      rightSidebarMaxPosition,
      leftSidebarPosition,
      rightSidebarPosition,
      openLeft,
      openRight,
      setHeader,
      startDragging,
      stopDragging,
    };
  },
};
</script>

<style lang="scss" scoped>
.header {
  height: 70px;
  border-bottom: 1px solid rgb(26, 179, 148);
}

.bottom-sidebar {
  height: 50px;
  border-top: 1px solid rgb(26, 179, 148);
}

.main-layout {
  height: calc(100vh - 120px);
  display: flex;
}

.main-content {
  flex: 1;
  height: 100%;
  padding: 20px;
}

.left-sidebar {
  border-right: 2px solid #000;
  height: 100%;
  background-color: rgb(155, 155, 197);
}

.right-sidebar {
  border-left: 2px solid #000;
  height: 100%;
  background-color: rgb(155, 155, 197);
}

.left-sidebar-divider {
  height: calc(100vh - 120px);
  width: 6px;
  background: #027712;
  transform: translateX(-3px);
  position: absolute;
  top: 70px;
  z-index: 1;
  cursor: ew-resize;
}
</style>