<template>
  <div class="right-sidebar-menu-item1">right-sidebar-menu-item1</div>
</template>

<script>
export default {
  name: "right-sidebar-menu-item1",
};
</script>

<style>
</style>