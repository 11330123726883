<template>
  <div class="flex flex-col items-start justify-start pl-2 gap-2 mt-2">
    <i class="fa-solid fa-cube" @click="setHeader('contacts')"></i>
    <i class="fa-solid fa-kaaba" @click="setHeader('phones')"></i>
    <i class="fa-brands fa-dashcube" @click="setHeader('settings')"></i>
    <i class="fa-solid fa-gear"></i>
    <i class="fa-solid fa-sliders"></i>
    <i class="fa-solid fa-list-check"></i>
  </div>
</template>

<script>
import { useStore } from "vuex";

export default {
  name: "SidebarMinIcons",
  props: {
    headersData: {
      type: Array,
      required: true,
    },
    openRightSidebar: {
      type: Boolean,
      default: false,
    },
    leftSidebarMinPosition: {
      type: Number,
      default: 2,
    },
    leftSidebarMaxPosition: {
      type: Number,
      default: 10,
    },
    rightSidebarMinPosition: {
      type: Number,
      default: 2,
    },
    rightSidebarMaxPosition: {
      type: Number,
      default: 10,
    },
  },
  setup(props) {
    const store = useStore();

    const openLeft = () => {
      this.$emit("openLeft");
    };

    const openRight = () => {
      this.$emit("openRight");
    };

    const setHeader = (value) => {
      const data = props.headersData[value];

      if (data) {
        store.commit("xadmin/SET_XADMIN_HEADER", data);
      }
    };

    return {
      openLeft,
      openRight,
      setHeader,
    };
  },
};
</script>

<style lang="scss" scoped>
i {
  cursor: pointer;
}
</style>